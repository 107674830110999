import { Component } from "@angular/core";

@Component({
  selector: 'no-content',
  template: `
    <div class="center not-found">
      <div class="not-found">404: Page not found</div>
    </div>
  `
})
export class NoContentComponent {

}
