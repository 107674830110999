import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from '../../../../core/services/common.service';
import { AuthService } from '../../../../core/services/auth.service';

@Component({
  selector: 'app-simultaneous-login-dialog',
  templateUrl: './simultaneous-login-dialog.component.html',
  styleUrls: ['./simultaneous-login-dialog.component.scss']
})
export class SimultaneousLoginDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SimultaneousLoginDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private commonService: CommonService) { }

  ngOnInit(): void {
  }
  public closeWithAction() {
    this.dialogRef.close(true);
  }

  public closeDialog() {
    this.dialogRef.close();
    this.commonService.logoutWithoutConfirmation();
  }
}
