

<div mat-dialog-title class="mb-32">
  <div class="title-container">
    <h3 class="m-0 ebony_color">{{data.header}}</h3>
    <mat-icon mat-dialog-close class="royal_color cursor-pointer">close</mat-icon>
  </div>
</div>

<div mat-dialog-content>
  <p class="ebony_color m-0 mb-32" [innerHTML]="heading"></p>
  <p class="ebony_color m-0 mb-32" [innerHTML]="data.subHeading"></p>

  <div class="d-flex mb-32" *ngIf="data.userInfo">
    <div class="w-100">
      <div class="midgray_color">Name</div>
      <div class="ebony_color bold">{{data.userInfo.name}}</div>
    </div>
    <div class="w-100">
      <div class="midgray_color">Permissions</div>
      <div class="ebony_color">{{data.userInfo.permission}}</div>
    </div>
  </div>
</div>

<div mat-dialog-actions class="justify-space-between">
  <div class="text-button" mat-dialog-close>
    <img src="./app/assets/icons/go_back_icon.svg" class="text-button-icon" />
    <p class="text-button-value bold m-0">No, Go Back</p>
  </div>
  <button mat-button class="primary-button" (click)="remove()">{{submitButtonText}}</button>
</div>
