import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookiesService {

  constructor() { }

  public areCookiesBlocked(): boolean {
    const testCookieName = 'testCookie';
    document.cookie = `${testCookieName}=test`;
    const cookieWasSet = document.cookie.indexOf(testCookieName + '=') !== -1;
    document.cookie = `${testCookieName}=; expires=Thu, 01 Jan 2024 00:00:00 GMT`;
    return cookieWasSet;
  }

  
}
