import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SupportDialogComponent } from '../../../shared/support/components/support-dialog/support-dialog.component';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public isNavSideOpen: boolean = false;
  public userInfo: any;
  public navigationOptions: any = [];
  public navigations: any = {};
  public roles: any = [];

  constructor(public dialog: MatDialog, public commonService: CommonService, public authService: AuthService, private apiService: ApiService) {
    this.roles = this.authService.getRoles();
    this.navigations = {
      home: 'Home',
      implementations: 'Implementations',
      settings: 'Settings',
      support: 'Support',
      analytics: 'Analytics',
      clients: 'Clients',
      report: 'Report',
      logout: 'Log Out'
    };

    this.commonService.userInfo$.subscribe((response) => {
      this.userInfo = response;
      this.navigationOptions = this.authService.getNavigationOptions();
    });
  }

  ngOnInit(): void {
  }

  public openSideNavBar(event: any = null) {
    this.isNavSideOpen = !this.isNavSideOpen;
  }

  public selectMenu(selectedItem: any) {
    let data = {};
    if (selectedItem.name == this.navigations.support) {
      const userDialogRef = this.dialog.open(SupportDialogComponent, this.commonService.getMatDialogOptions(data, 'mat-dialog-container__full-screen'));
    }

    else if (selectedItem.name == this.navigations.logout) {
      this.authService.logout();
    }
  }
}
