import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  isProgressBarRunning = new Subject<boolean>();
  isLoaderRunning = new Subject<boolean>();

  constructor() { }

  startProgressBar() {
    this.isProgressBarRunning.next(true);
  }

  stopProgressBar() {
    this.isProgressBarRunning.next(false);
  }

  startLoader() {
    this.isLoaderRunning.next(true);
  }

  stopLoader() {
    this.isLoaderRunning.next(false);
  }
}
