<div class="side-nav-bar white-sidebar" *ngIf="userInfo.name">
  <div class="side-nav-bar-inner">
    <img class="mb-32" src="./app/assets/icons/Thrivepass_logo_heart.svg" />
    <h3 class="ebony_color mb-8 client-name">{{userInfo.name}}</h3>
    <h3 class="ebony_color mb-8 client-initial">{{userInfo.initials}}</h3>
    <p class="cmp-name mb-36 ebony_color">{{userInfo.company}}</p>

    <div class="menu-container">
      <a *ngFor="let option of navigationOptions" class="menu-item cursor-pointer" [routerLink]="option.route != null ?option.route : null" routerLinkActive="selected-menu" (click)="selectMenu(option)">
        <img src="{{option.icon}}" /><span class="menu-name">{{option.name}}</span>
      </a>
    </div>
  </div>
</div>
