import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../../../../core/services/api.service';
import { CommonService } from '../../../../core/services/common.service';

@Component({
  selector: 'support-dialog',
  templateUrl: './support-dialog.component.html',
  styleUrls: ['./support-dialog.component.scss']
})
export class SupportDialogComponent implements OnInit {
  @ViewChild("supportForm") public supportForm: HTMLFormElement | null = null;
  public currentStep: number;
  public totalSteps: number;
  public supportInfo: any = {};
  public isLoading: boolean = false;

  constructor(public dialogRef: MatDialogRef<SupportDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private apiService: ApiService, private commonService: CommonService) {
    this.currentStep = 1;
    this.totalSteps = 1;

    this.commonService.userInfo$.subscribe((res) => {
      this.supportInfo = res; 
    });

    this.supportInfo.message = '';
  }

  ngOnInit(): void {
  }

  public addSupport() {
    if (this.isLoading || !this.supportForm?.valid)
      return;

    this.isLoading = true;
    this.apiService.post('client/support/request', this.supportInfo).subscribe((response: any) => {
      if (response.success) {
        this.isLoading = false;
        this.commonService.showInformationSnackbar('Success  🎉', 'Your support request has been sent.');
        this.dialogRef.close();
      }
    }, (error) => { this.isLoading = false; });
  }
}
