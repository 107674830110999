import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SideNavBarComponent } from './components/side-nav-bar/side-nav-bar.component';
import { RouterModule } from '@angular/router';
import { MaterialModule } from './material.module';
import { NoContentComponent } from './components/no-content/no-content.component';
import { LoaderComponent } from './components/loader/loader.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { AuthService } from './services/auth.service';
import { RedirectGuard } from './guards/redirect.guard';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { TopBannerComponent } from './components/top-banner/top-banner.component';
import { AdminGuard } from './guards/admin.guard';
import { ClientAdminGuard } from './guards/client-admin.guard';
import { ClientService } from '../client/services/client.service';
import { ApiService } from './services/api.service';
import { DeleteDialogComponent } from './components/delete-dialog/delete-dialog.component';
import { ToastComponent } from './components/toast/toast.component';
import { UserIdleService } from './services/user-idle.service';
import { CookiesService } from './services/cookies.service';
import { AcceptCookiesComponent } from './components/accept-cookies/accept-cookies.component';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SideNavBarComponent,
    NoContentComponent,
    LoaderComponent,
    TopBannerComponent,
    DeleteDialogComponent,
    ToastComponent,
    AcceptCookiesComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    HttpClientModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    SideNavBarComponent,
    NoContentComponent,
    LoaderComponent,
    HttpClientModule,
    TopBannerComponent,
    DeleteDialogComponent,
    ToastComponent,
    AcceptCookiesComponent
  ],
  providers: [
    //Services
    AuthService,
    ClientService,
    ApiService,
    UserIdleService,
    CookiesService,
    //Guards
    RedirectGuard,
    AdminGuard,
    ClientAdminGuard,

    //Interceptors
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ]
})
export class CoreModule { }
