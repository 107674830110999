<div class="main_container">
  <div class="body_container">

    <div class="thrive_image">
      <p class="head_text">Your Cookies are Disabled</p>
    </div>

    <div class="text_message mt-16 wid">
      <p class="normal_text">To use our website, cookies must be enabled in your browser settings. They are currently disabled, blocking cookies that are essential to making our website work.</p>
    </div>

    <div class="main_text mt-16 wid">
      <p class="normal_text">For more information on how to enable cookies, visit the help section of your browser. Once you’ve enabled cookies, refresh this page to access our website. </p>
    </div>
  </div>
</div>
