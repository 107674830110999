<div class="container royal_bgcolor" *ngIf="data">
  <div class="submit-text" *ngIf="data.result && data.result !== ''">{{data.result}}</div>
  <div class="message" *ngIf="data.message && data.message !== ''">{{data.message}}</div>
  <div class='submit-button' *ngIf="data && data.buttonText && data.buttonText !== ''">
    <button mat-button color="white" class="mat-button cursor-pointer" (click)="close()">
      {{data.buttonText}}
    </button>
  </div>

</div>
