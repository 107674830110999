<mat-progress-bar *ngIf="isProgressRunning | async" mode="indeterminate"></mat-progress-bar>
<header class="header" *ngIf="isLoggedIn"></header>
<side-nav-bar *ngIf="isLoggedIn"></side-nav-bar>
<router-outlet></router-outlet>
<loader *ngIf="isLoaderRunning | async"></loader>
<div class="cookies-dialog" *ngIf="isLoggedIn">
  <accept-cookies *ngIf="isCookiesAccepted" class="accept_cookies"></accept-cookies>
</div>
<div class="cookies-dialog" *ngIf="!isLoggedIn">
  <accept-cookies *ngIf="isAcceptedBeforeLogin"  class="accept_cookies" (isCookiesAcceptedBeforeLogin)="cookiesBeforeLogin($event)"></accept-cookies>
</div>
<footer class="footer" *ngIf="isLoggedIn"></footer>

