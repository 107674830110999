import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './modules/core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './modules/core/material.module';
import { ConfigurationService } from './modules/core/services/configuration.service';
import { MsalModule } from '@azure/msal-angular';
import { IConfig, NgxMaskModule } from 'ngx-mask';

export function callConfig(configService: ConfigurationService) {
  const x = () => configService.loadConfig();
  return x;
}

export function getConfig() {
  var request = new XMLHttpRequest();
  request.open('GET', "/config", false);
  request.send(null);
  const response = JSON.parse(request.responseText);
  return response.value.msalConfig;
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    BrowserAnimationsModule,
    MaterialModule,
    MsalModule.forRoot(getConfig(),
      {
        popUp: false,
        protectedResourceMap: new Map([
          ['https://thrivepasslogin.b2clogin.com/testservice/read', ['user.read']],
          ['https://thrivepasslogin.b2clogin.com/testservice/write', ['user.write']]
        ])
      })
  ],
  providers: [
    //Services
    ConfigurationService, //Global services shoudl be injected in app.module

    //Initilizer
    { provide: APP_INITIALIZER, useFactory: callConfig, deps: [ConfigurationService], multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
