import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MsalService } from '@azure/msal-angular';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { ToastComponent } from '../components/toast/toast.component';
import { TopBannerComponent } from '../components/top-banner/top-banner.component';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { LoaderService } from './loader.service';
import { UserIdleConfig, UserIdleService } from './user-idle.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public clientUrl: string = '';
  public idleTime = 1200; //unit is 'seconds'
  public logoutTime = 60; //unit is 'seconds'
  public snackbarOpened: boolean = false;
  public isCookiesAccepted: boolean = false;
  public userInfo = new BehaviorSubject<any>({});
  public userInfo$ = this.userInfo.asObservable();
  public pattern: any = {
    username: "^[a-zA-Z0-9\\.-]+$",
    password: "^((?=.*[a-z])(?=.*[A-Z])(?=.*\\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])|(?=.*[a-z])(?=.*\\d)(?=.*[^A-Za-z0-9])|(?=.*[A-Z])(?=.*\\d)(?=.*[^A-Za-z0-9]))([A-Za-z\\d@#$%^&amp;*\\-_+=[\\]{}|\\:',?\\`~&quot;();!]|\\.(?!@)){8,64}$",
    email: '^\\b[\\w\\.-]+@[\\w\\.-]+\\.\\w{2,4}\\b',
    customEmailPattern: '^[\\w\\.-]+(\\+\\d+)?@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'
  }

  constructor(private snackBar: MatSnackBar, private userIdleService: UserIdleService, private msalService: MsalService, private loaderService: LoaderService, private apiService: ApiService) { }

  public getMatDialogOptions(data: any = null, panelClass: string = '', disableClose: boolean = false): any {
    let matDialogOptions = MatDialogOptions;
    if (data !== null)
      matDialogOptions.data = data;

    matDialogOptions.panelClass = panelClass;
    matDialogOptions.disableClose = disableClose;
    return matDialogOptions;
  }

  public downloadFile(file: any) {
    const nav = (window.navigator as any);
    if (file && file.name && (file.base64 || file.byteArray)) {
      if (nav.msSaveOrOpenBlob) {
        const byteCharacters = atob(file.base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "data:application/octet-stream;base64" });

        nav.msSaveOrOpenBlob(blob, file.name);
      }
      else {
        var fileContent = file.base64 ? file.base64 : file.byteArray;
        const element = document.createElement('a');
        element.setAttribute('href', 'data:application/octet-stream;base64,' + fileContent);
        element.setAttribute('download', file.name);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
    }
    else {
      var fileContent = file.base64 ? file.base64 : file.byteArray;
      const element = document.createElement('a');
      element.setAttribute('href', 'data:application/octet-stream;base64,' + fileContent);
      element.setAttribute('download', file.name);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  }

  public showTopBanner(message: string, buttonText: string = '', timeOut: number = 6000, actionToPerform: { (): void } | null = null, showSupportMail: boolean = false) {
    let snackbarRef = this.snackBar.openFromComponent(TopBannerComponent, {
      data: {
        message: message,
        actionButtonText: buttonText,
        showSupportMail: showSupportMail
      }, duration: timeOut,
      panelClass: 'top-banner-default',
      verticalPosition: 'top', // 'top' | 'bottom'
      horizontalPosition: 'center', //'start' | 'center' | 'end' | 'left' | 'right'
    });

    if (actionToPerform) {
      snackbarRef.afterDismissed().subscribe((action: any) => {
        if (action.dismissedByAction)
          actionToPerform();
      });
    }
  }

  public setUserInfo(response: any) {
    this.userInfo.next(response);
  }

  public getUserInfo(): any {
    return this.userInfo.value;
  }

  public getMomentDate(dateValue: Date, format: string = 'YYYY-MM-DD') {
    const momentDate = moment(dateValue, format);
    return momentDate.isValid() ? momentDate.format(format) : null;
  }

  public getMomentDateFromString(dateValue: string, dateFormat: string = 'YYYY-MM-DD') {
    const momentDate = moment(dateValue, dateFormat);
    return momentDate.isValid() ? momentDate.format(dateFormat) : null;
  }

  public showInformationSnackbar(result: string = '', message: string = '', buttonText: string = '', actionToPerform: { (): void } | null = null): void {
    let snackbarRef = this.snackBar.openFromComponent(ToastComponent, {
      data: {
        result: result,
        message: message,
        buttonText: buttonText
      }, duration: 6000,
      panelClass: 'transparent-bottom-default',
      verticalPosition: 'bottom', // 'top' | 'bottom'
      horizontalPosition: 'left', //'start' | 'center' | 'end' | 'left' | 'right'
    });

    if (actionToPerform) {
      snackbarRef.afterDismissed().subscribe((action) => {
        actionToPerform();
      });
    }
  }

  public checkAndLogoutUser() {
    if (localStorage.lastEventDate) {
      var lastEventDateStored = localStorage.getItem("lastEventDate");
      var lastEventDate = lastEventDateStored != null ? new Date(lastEventDateStored) : new Date();
      var currentDate = new Date();
      var seconds = (currentDate.getTime() - lastEventDate.getTime()) / 1000;
      if (this.idleTime < seconds) {
        localStorage.setItem("isStopTimer", "false");
        this.logout();
      }
    }
  }

  public setTimerConfigValue() {
    var obj = new UserIdleConfig();
    this.userIdleService.stopWatching();
    obj.idle = this.idleTime;
    obj.timeout = this.logoutTime;
    this.userIdleService.setConfigValues(obj);
  }

  public setUserIdleWatch() {
    this.userIdleService.startWatching();
    this.userIdleService.onTimerStart().subscribe((count:any) => {
      if (count) {
        this.showSnackbarForInactivity();
      }
    });
    this.userIdleService.onTimeout().subscribe(() => {
      this.logout();
    });
  }

  public showSnackbarForInactivity() {
    if (!this.snackbarOpened) {
      this.snackbarOpened = true;
      this.setLogoutAlertSnackbar();
    }
  }

  public setLogoutAlertSnackbar(showSupportMail: boolean = false) {
    let snackBarRef = this.snackBar.openFromComponent(TopBannerComponent, {
      data: {
        message: 'User is going to logout in ' + this.logoutTime + ' seconds. Do you want to stay?',
        actionButtonText: 'Yes',
        showSupportMail: showSupportMail
      },
      duration: (this.logoutTime * 1000),
      panelClass: 'top-banner-default',
      verticalPosition: 'top', // 'top' | 'bottom'
      horizontalPosition: 'center', //'start' | 'center' | 'end' | 'left' | 'right'
    });

    snackBarRef.onAction().subscribe(() => {
      this.userIdleService.stopTimer();
      this.snackbarOpened = false;
    });
  }
  public logout() {
    this.loaderService.startProgressBar();
    this.msalService.logout();
  }

  public checkboxMapping(data: any, toBeMapped: any, isOld = false) {
    if (data != null) {
      var dataArray = data.split(',');
      dataArray.pop();
      dataArray.forEach((elm: any) => {
        toBeMapped.forEach((elm1: any) => {
          if (elm == elm1.name) {
            elm1.isSelected = true;
            if (isOld) {
              elm1.isOldSelected = true;
            }
          }
        })
      })
    }
  }

  public checkBoxToString(data: any) {
    var str = '';
    data.forEach((elm: any) => {
      if (elm.isSelected) {
        str = str + elm.name + ',';
      }
    })
    return str;
  }

  makePdf(name:any) {
    let self = this;
    var doc = new jsPDF('p', 'mm');
    let firstImageHeight = 0;
    var data = document.getElementById('intakeForm') as HTMLElement;
    html2canvas(data).then(function (canvas) {
      var imgData = canvas.toDataURL('image/png');
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      firstImageHeight = imgHeight;



      var position = 0;
      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;



      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }



      var date = new Date();
      let mmddyyyy = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
      doc.save(name + mmddyyyy + '.pdf');
      //self.openPDFDownloadModal();
    });

  }

  public completeTaskDirectlyByAdmin(milestoneTaskId: any, acceptDocument: boolean=false,) {
    var request = {
      milestoneTaskId: milestoneTaskId,
      reviewStatus: acceptDocument,
      message: 'this task is completed by admin',
      isAdmin:true,
    }
    this.apiService.post('admin/client/implementation/task/review/submit', null, request).subscribe((response: any) => {
      if (response.success) {
        this.showInformationSnackbar('Success  🎉', 'You have successfully Completed Task .');
      }
    })
  }
}

export const MatDialogOptions: any = {
  disableClose: false,
  closeOnNavigation: true,
  maxWidth: '100vw',
  height: '100%'
};
