import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {
  public heading: string;
  public submitButtonText: string = 'Yes, Delete';
  constructor(public dialogRef: MatDialogRef<DeleteDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.heading = 'You clicked ‘Delete User.’ This action will immediately remove the user from our system and block further access. Are you sure you want to proceed?';
    if (data.heading)
      this.heading = data.heading;
    if (data.submitButtonText)
      this.submitButtonText = data.submitButtonText;
  }

  ngOnInit(): void {
  }

  public remove() {
    this.dialogRef.close(true);
  }
}
