<div class="glacier_bgcolor medium-drop" mat-dialog-title>
  <div>
    <img src="./app/assets/icons/wordmark.svg" class="wordmark-img" />
    <p class="midgray_color">Support Tool</p>
  </div>

  <img src="./app/assets/icons/close-icon.svg" alt="Close" mat-dialog-close class="cursor-pointer close-icon" />
</div>

<div mat-dialog-content>
  <div class="dialog-content-container">
    <div class="heading ebony_color mb-8">Request Support</div>
    <div class="small-text-button small-text-button-disabled">
      <img src="./app/assets/icons/keyboard_arrow_down_disabled.svg" class="text-button-icon" />
      <p class="text-button-value bold m-0">Step {{currentStep}} of {{totalSteps}}</p>
    </div>
    <form id="supportForm" #supportForm="ngForm">
      <mat-card class="shorter-drop">
        <mat-card-content>
          <p class="title astronaut_color mb-8">{{currentStep}}.</p>
          <p class="title astronaut_color m-0 mb-32">Request Information</p>

          <div class="d-flex align-center gap-32">
            <div class="w-100">
              <p class="bold m-0 small mb-4">Name</p>
              <p class="m-0 description">{{supportInfo.name}}</p>
            </div>
            <div class="w-100">
              <p class="bold m-0 small mb-4">Company</p>
              <p class="m-0 description">{{supportInfo.company}}</p>
            </div>
          </div>

          <div class="form-field margin-bottom-0">
            <p class="bold smaller">What do you need help with?</p>
            <mat-form-field floatLabel="never" hideRequiredMarker appearance="outline">
              <textarea class="help-text-input" id="helpMessage" name="HelpMessage" #HelpMessage="ngModel" type='text' matInput [(ngModel)]="supportInfo.message" placeholder="Please provide a description of what you need help with so we can best support you." required maxlength="500"></textarea>
              <mat-error>
                Enter your message.
              </mat-error>
            </mat-form-field>
            <div class="help-text-counter">{{supportInfo.message.length}}/500</div>
          </div>
        </mat-card-content>
      </mat-card>
    </form>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button class="primary-button" (click)="addSupport()" [disabled]="isLoading || !supportForm.valid">Submit</button>
</div>
