import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  private baseUrl: string;
  constructor(private apiService: ApiService) {
    this.baseUrl = 'http://localhost:5002/';
  }

  public getUserInfo() {
    this.apiService.get(this.baseUrl + '')
  }
}
