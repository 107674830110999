<div class="primary-container">
  <span class="header-div">
    <span class="header-text fw-600">
      {{data.modalTitle}}
    </span>
    <button mat-icon-button mat-dialog-close class="close-button">
      <img matTooltip="Close" src="./app/assets/icons/bloom-close-icon.svg" (click)="closeDialog()" />
    </button>
  </span>
  <div class="content-div">
    Our system shows you are currently logged in on another device:
  </div>
  <div class="content-div-data">
    <div class="item"> <div class="data-header">IP Address</div> <div class="data-text">{{data.ipAddress}}</div></div>
    <div class="item"> <div class="data-header">Device Type</div> <div class="data-text">{{data.deviceId}}</div></div>
  </div>
  <div class="content-div">
    By logging in here, you’ll be logged out of all other sessions. Are you sure you want to proceed?
  </div>
  <div class="button-div">
    <button class="cancel-button-div" type="button" mat-button matSuffix mat-icon-button>
      <img src="./app/assets/icons/bloom-arrow-back-icon.svg" (click)="closeDialog()" />
      <span class="text-transform padding-left-4px font-weight-600 font-size-14 royal" (click)="closeDialog()">No, Cancel Log In</span>
    </button>
    <button mat-button type="button" class="yes-button mat-button-primary-child primary-button" (click)="closeWithAction()">{{data.positiveButtonText}}</button>
  </div>
</div>

