import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { ApiService } from './api.service';
import { CommonService } from './common.service';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private msalService: MsalService, private commonService: CommonService, private loaderService: LoaderService) { }

  async loginRedirect() {
    this.loaderService.startProgressBar();
    this.msalService.loginRedirect();
  }

  async logout() {
    this.loaderService.startProgressBar();
    this.msalService.logout();
  }

  public isLoggedIn() {
    return this.msalService.getAccount() != null;
  }

  public getRoles(): string[] {
    var userInfo = this.commonService.getUserInfo();
    return (userInfo && userInfo.roles) ? userInfo.roles : [];
  }

  public getUserNameObject(): any {
    const user = this.msalService.getAccount();
    if (user) {
      let nameObject = { 'firstName': user.name, 'fullName': user.name, 'initials': user.name[0], 'username': '', email: '', b2CObjectId: '' };
      nameObject.b2CObjectId = user.accountIdentifier;

      if (user.idToken && user.idToken['given_name'] && user.idToken['family_name']) {
        nameObject.firstName = user.idToken['given_name'];
        nameObject.fullName = user.idToken['given_name'] + ' ' + user.idToken['family_name'];
        nameObject.initials = user.idToken['given_name'][0] + user.idToken['family_name'][0];
      }

      if (user.idToken && user.idToken['extension_DisplayUsername'])
        nameObject.username = user.idToken['extension_DisplayUsername'];

      if (user.idToken && user.idToken['emails'] && user.idToken['emails'].length > 0)
        nameObject.email = user.idToken['emails'][0];

      return nameObject;
    }

    return [];
  }

  public getNavigationOptions(): any[] {
    var navigationOptions = [];
    var roles = this.getRoles();
    if (roles.indexOf('admin') > -1) {
      navigationOptions = [
        { name: 'Home', route: 'admin/dashboard', icon: './app/assets/icons/home_menu_icons.svg' },
        { name: 'Analytics', route: 'admin/analytics/dashboard', icon: './app/assets/icons/analytics_menu_icon.svg' },
        { name: 'Clients', route: 'admin/clients', icon: './app/assets/icons/implementation_menu_icon.svg' },
        { name: 'Implementations', route: 'admin/implementations', icon: './app/assets/icons/implementation_menu_icon.svg' },
        { name: 'Report', route: 'admin/reports', icon: './app/assets/icons/report_menu_icon.svg' },
        { name: 'Log Out', route: null, icon: './app/assets/icons/logout_menu_icon.svg' },      
      ];
    }
    else {
      navigationOptions = [
        { name: 'Home', route: 'client/dashboard', icon: './app/assets/icons/home_menu_icons.svg' },
        { name: 'Implementations', route: 'client/implementations', icon: './app/assets/icons/implementation_menu_icon.svg' },
        { name: 'Settings', route: 'client/settings', icon: './app/assets/icons/setting_menu_icon.svg' },
        { name: 'Support', route: null, icon: './app/assets/icons/support_menu_icon.svg' },
        { name: 'Log Out', route: null, icon: './app/assets/icons/logout_menu_icon.svg' }
      ];
    }

    return navigationOptions;
  }
}
