<mat-toolbar class="mat_header_mobile white_bgcolor">
  <div class="mat_heading_mobile">
    <button type="button" aria-label="Toggle sidenav" mat-icon-button class="mat_heading_toggleicon_mobile" (click)="drawer.toggle();">
      <img *ngIf="!isNavSideOpen" class="toggle_icon" src="./app/assets/icons/toggle_icon.svg" />
      <img *ngIf="isNavSideOpen" class="toggle_icon" src="./app/assets/icons/close-icon.svg" />
    </button>

    <div class="mat_heading_icon_mobile ebony_color">
      <img class="company_logo_mobile" src="./app/assets/icons/logo_icon.svg" />
      <div class="company_name margin_8_left astronaut_color"><span class="bolder">Thrive</span><span class="bold">Pass</span></div>
    </div>

    <p class="mat_heading_username_mobile caption bold royal_bgcolor white_color m-0">
      {{userInfo.initials}}
    </p>
  </div>
</mat-toolbar>

<mat-drawer-container class="mat-toggle-bar" [hasBackdrop]="true">
  <mat-drawer #drawer mode="over" (openedChange)="openSideNavBar($event)">
    <div class="side-nav-bar white_bgcolor" *ngIf="userInfo.name">
      <div class="side-nav-bar-inner">
        <img class="mb-32" src="./app/assets/icons/Thrivepass_logo_heart.svg" />
        <h3 class="ebony_color mb-8">{{userInfo.name}}</h3>
        <p class="cmp-name mb-36 ebony_color">{{userInfo.company}}</p>

        <div class="menu-container">
          <a *ngFor="let option of navigationOptions" class="menu-item cursor-pointer" [routerLink]="option.route != null ?option.route : null" routerLinkActive="selected-menu" (click)="selectMenu(option)">
            <img src="{{option.icon}}" /><span class="menu-name">{{option.name}}</span>
          </a>
        </div>
      </div>
    </div>
  </mat-drawer>
</mat-drawer-container>

