import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { BlockedCookiesDialogComponent } from '../../shared/implementation/components/blocked-cookies-dialog/blocked-cookies-dialog.component';
import { BlockedCookiesInfoComponent } from '../../shared/implementation/components/blocked-cookies-info/blocked-cookies-info.component';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import { CommonService } from '../services/common.service';
import { CookiesService } from '../services/cookies.service';
import { LoaderService } from '../services/loader.service';


@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService, private apiService: ApiService, private commonService: CommonService, private loaderService: LoaderService, private cookiesService: CookiesService, private dialog: MatDialog) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.cookiesService.areCookiesBlocked()) {

      if (this.authService.isLoggedIn()) {

        this.loaderService.startLoader();
        var isConsentedBefore: boolean = false;

        return this.apiService.get('user/info/get').pipe(map((response: any) => {
          if (response.success) {
            this.commonService.setUserInfo(response.data);

            if (state.url == '/login' || state.url.split('?')[0] == '/registration' || state.url == '/') {
              var roles = this.authService.getRoles();
              if (roles.indexOf('admin') > -1) {
                this.router.navigateByUrl('/admin/dashboard');
              }
              if (roles.indexOf('clientAdmin') > -1) {
                this.router.navigateByUrl('/client/dashboard');
              }
            }
            if (state.url == '/admin') {
              this.router.navigateByUrl('/admin/dashboard');
            }
            if (state.url == '/client') {
              this.router.navigateByUrl('/client/dashboard');
            }
          }

          this.loaderService.stopLoader()
          return true;
        }));
      } else if (state.url == '/' && !this.authService.isLoggedIn()) {
        this.router.navigateByUrl('/login');
      }
    }
    else {
      const userDialogRef = this.dialog.open(BlockedCookiesInfoComponent, this.commonService.getMatDialogOptions(null, 'mat-dialog-container__full-screen'));

    }
    return true;
  }
}
